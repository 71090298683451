export type OpenDayKey = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun' | 'bkh'

const dayMap: Record<OpenDayKey, string> = {
  mon: 'data.product.open_days.options.mon',
  tue: 'data.product.open_days.options.tue',
  wed: 'data.product.open_days.options.wed',
  thu: 'data.product.open_days.options.thu',
  fri: 'data.product.open_days.options.fri',
  sat: 'data.product.open_days.options.sat',
  sun: 'data.product.open_days.options.sun',
  bkh: 'data.product.open_days.options.bkh',
}

export const useGetTranslatedCamperOpenDays = () => {
  const { $i18n: { t } } = useNuxtApp()

  return {
    getTranslatedCamperOpenDays: (day: OpenDayKey) => {
      const key = dayMap[day]
      return t(key)
    },
  }
}
